import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTabs, TabPanel } from 'react-headless-tabs';
import { useNavigate, useMatch } from 'react-router-dom';

import { TabSelector } from '@/components/Elements/Tabs/TabSelector';
import { getDeepLink, QueryParamValue } from '@/utils/format';

type EOTabsProps = {
  labels: string[];
  components: Array<React.ReactElement>;
  basePathName: string;
  basePathRoute: string;
  queryParams?: Record<string, QueryParamValue>;
  onChange?: (value: string) => void;
};

const getTabKey = (tab: string): string => {
  return tab
    .split(' ')
    .map((value) => value.toLowerCase())
    .join('-');
};

export const EOTabs: React.FC<EOTabsProps> = ({
  labels,
  components,
  basePathName,
  basePathRoute,
  queryParams,
  onChange,
}) => {
  const tabKeys = labels.map((tab) => getTabKey(tab));
  const tabRouteMatch = useMatch(`${basePathRoute}/:tab`);

  const activeTab = get(tabRouteMatch, 'params.tab') || tabKeys[0];
  const [selectedTab, setSelectedTab] = useTabs(tabKeys, activeTab);
  const [resetFilters, setResetFilters] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setResetFilters(false);
    setSelectedTab(activeTab);
  }, [resetFilters, activeTab, setSelectedTab]);

  useEffect(() => {
    const handleBrowserBackButton = (): void => {
      setResetFilters(true);
    };

    window.addEventListener('popstate', handleBrowserBackButton);
    return () => window.removeEventListener('popstate', handleBrowserBackButton);
  }, []);

  const handleSelectTab = (tab: string): void => {
    setSelectedTab(tab);
    navigate(getDeepLink(`${basePathName}/${tab}`, queryParams));
    setTimeout(() => {
      // wait until tab navigation done to prevent duplicated navigate from DeepLinkContext
      onChange && onChange(tab);
    });
  };

  return (
    <>
      <nav className="flex border-b border-gray-300 mt-3">
        {labels.map((tabLabel, index) => (
          <TabSelector
            key={tabKeys[index]}
            isActive={selectedTab === tabKeys[index]}
            onClick={() => handleSelectTab(tabKeys[index])}
          >
            {tabLabel}
          </TabSelector>
        ))}
      </nav>
      <div className="pt-8 pb-8">
        {tabKeys.map((tab, index) => {
          if (selectedTab !== tab) {
            return null;
          }

          return (
            <TabPanel key={tab} hidden={false}>
              {components[index]}
            </TabPanel>
          );
        })}
      </div>
    </>
  );
};
